<template>
  <v-container class="background fill-height d-block" fluid>
    <!--  Navigation  -->
    <u-project-top-nav :title="projectName ? projectName : 'Untitled'" extended>
      <template #extension>
        <u-stepper-progression
          :step="stepper.step"
          :sub-step="stepper.subSteps"
          :subtitle="stepper.subtitle"
          :title="stepper.title"
          @click:prev="$router.push(stepper.prevLink)"
          @click:next="$router.push(stepper.nextLink)"
        />
      </template>
    </u-project-top-nav>

    <!--  Breadcrumbs  -->
    <v-container class="pb-0" fluid>
      <u-breadcrumbs :items="breadcrumbItems" />
      <h1 class="secondary--text">Create Project</h1>
    </v-container>

    <v-row align="stretch" class="content my-2">
      <!--  Form  -->
      <v-col cols="12" md="5">
        <v-sheet
          class="
            pa-6
            py-12
            card-border
            fill-height
            d-flex
            flex-column
            justify-center
          "
          outlined
          rounded="lg"
        >
          <u-text-field
            ref="projectName"
            v-model="projectName"
            :hide-details="false"
            :rules="[rules.required, rules.noRepeat]"
            label="Project Name"
            large
            placeholder="Eg - JFK Terminal 1 De-congestion"
            required
          />
          <u-text-area
            v-model="projectDescription"
            :rows="8"
            class="mb-6"
            label="Project Description"
            large
            placeholder="Describe your project's details here"
          />
          <v-spacer></v-spacer>
          <label class="body-1 font-weight-bold secondary--text">
            Location
          </label>
          <p class="subtitle-1 font-weight-bold py-2">
            {{ coordinateString }}
          </p>
        </v-sheet>
      </v-col>

      <!--  Map  -->
      <v-col cols="12" md="7">
        <v-sheet
          class="card-border fill-height overflow-hidden"
          outlined
          rounded="lg"
        >
          <u-map
            allow-map-search
            class="fill-height"
            show-navigation-controls
            show-search-marker
            @onSearchResult="updateCoords"
          ></u-map>
        </v-sheet>
      </v-col>
    </v-row>

    <!--  Action Buttons  -->
    <v-row class="px-4 my-1" justify="end">
      <u-button
        class="text-capitalize font-weight-bold secondary--text"
        color="primary"
        large
        @click="create_project"
      >
        Create
      </u-button>
    </v-row>
  </v-container>
</template>

<script>
import {
  UBreadcrumbs,
  UButton,
  UMap,
  UTextArea,
  UTextField,
} from "@/components/base";
import { UProjectTopNav, UStepperProgression } from "@/components/common";
import { PROJECT_SETUP_STEPPER } from "@/utils/navStepper.data";
import { PROJECT_SETUP_BREADCRUMBS } from "@/utils/breadcrumbs.data";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "CreateProject",
  components: {
    UMap,
    UStepperProgression,
    UButton,
    UTextArea,
    UTextField,
    UBreadcrumbs,
    UProjectTopNav,
  },
  mounted() {
    this.clearProjectState();
  },
  computed: {
    ...mapState("User", ["selectedTemplate"]),
    ...mapGetters("Project", ["activeProjectId"]),
    hasCoords() {
      return (
        Object.keys(this.searchCoordinates).includes("lat") &&
        Object.keys(this.searchCoordinates).includes("lng")
      );
    },
  },
  data() {
    return {
      stepper: PROJECT_SETUP_STEPPER.createProject,
      breadcrumbItems: PROJECT_SETUP_BREADCRUMBS,
      // Form
      projectName: "",
      projectDescription: "",
      searchCoordinates: {},
      coordinateString: "",
      rules: {
        required: (value) => !!value || "Required.",
        noRepeat: (value) => {
          const projectExists = this.$store.state.User.projects.some(
            (project) => project.name === value,
          );
          return projectExists ? "Project name already exists." : true;
        },
      },
    };
  },
  methods: {
    // Clear Project State values
    ...mapActions("Project", ["clearProjectState"]),
    updateCoords(coords) {
      const { lat, lng } = coords;
      this.searchCoordinates = { ...coords };
      this.coordinateString = `${lat}, ${lng}`;
    },
    async create_project() {
      if (!this.$refs.projectName.validate() && !this.hasCoords) return;
      const params = {
        name: this.projectName,
        description: this.projectDescription,
        location: "",
        coordinates: {
          lat: this.searchCoordinates.lat,
          long: this.searchCoordinates.lng,
        },
        template_id: this.selectedTemplate?.template_id ?? null,
      };
      await this.$store.dispatch("User/createProject", params);
      // TODO: Prevent navigation if project creation fails
      this.$router
        .push({
          name: "Video Library",
          params: { project_id: this.activeProjectId },
        })
        .then();
    },
  },
};
</script>

<style lang="scss" scoped>
.card-border {
  border: 2px solid var(--v-gray-7-base);
}
</style>
